import { RootState } from 'app/store';
import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import AuthFailed from './AuthFailed';
import AuthInProgress from './AuthInProgress';
import AuthRequired from './AuthRequired';

export default function AuthRequiredPage({ children }: PropsWithChildren<Record<string, unknown>>) {
    const auth = useSelector((state: RootState) => state.auth);

    if (auth.account)
        //user is authenticated
        return <>{children}</>;
    else {
        //user is not authenticated
        if (auth.isLoading)
            //authentication in progress
            return <AuthInProgress />;
        else if (auth.error)
            //authentication failed
            return <AuthFailed error={auth.error} />;
        else {
            //start the login process
            //console.log("pass")
            //dispatch(login())
            return <AuthRequired />;
        }
    }
}
