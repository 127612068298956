import React from 'react';

export default function AppLoading() {
    return (
        <div className="splash-screen">
            <div className="splash-screen-container">
                <div className="splash-screen-circle-tick"></div>
                <div className="splash-screen-content">
                    <h3>Caricamento</h3>
                    <p className="text-muted">
                        <small>Attendere prego</small>
                    </p>
                </div>
            </div>
        </div>
    );
}
