import authService from 'app/auth/authService';
import { Category, Shop } from 'app/models';
import Axios from 'axios';
import FormData from 'form-data';
import store from 'app/store';
import { noLogin } from 'app/auth/authSlice';
import { saveAs } from 'file-saver';
import urljoin from 'url-join';

const axiosDashboard = Axios.create({
    baseURL: process.env.REACT_APP_API_DOMAIN,
});

axiosDashboard.interceptors.request.use(
    async (config) => {
        const isDashboard = config.url?.includes('dashboard');
        if (isDashboard) {
            try {
                const token = await authService.getApiToken();
                config.headers['Authorization'] = `Bearer ${token}`;
            } catch (e) {
                console.error(e);
                Promise.reject(e);
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosDashboard.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) store.dispatch(noLogin());
        return Promise.reject(error);
    }
);

const DEFAULT_PAGE_SIZE = 10;
//const CONTENT_DISPOSITION_REGEX = /filename\*=(?<charset>.*)'(?<language>.*)'(?<filename>.*);?\s?/

export async function getShops() {
    return axiosDashboard.get('/api/dashboard/shops').then((response) => response.data);
}

export async function getCategories() {
    return axiosDashboard.get('/api/dashboard/shops/categories').then((response) => response.data);
}

export async function getShop(id: string): Promise<Shop> {
    return axiosDashboard.get(`/api/dashboard/shops/${id}`).then((response) => response.data);
}

export async function updateShop(id: string, data: Shop) {
    return axiosDashboard.put(`/api/dashboard/shops/${id}`, data).then((response) => {
        return response.data;
    });
}

export async function createShop(data: Shop) {
    return axiosDashboard.post('/api/dashboard/shops', data);
}

export async function activateShop(shopCode: string) {
    return axiosDashboard.post('/api/dashboard/shops/activate', {
        secretCode: shopCode,
    });
}

export async function getShoppingVouchers(shopId: string, page = 1, pageSize: number = DEFAULT_PAGE_SIZE) {
    return axiosDashboard
        .get(`/api/dashboard/shops/${shopId}/shoppingVouchers`, {
            params: { pageSize, page },
        })
        .then((r) => r.data);
}

export async function redeemShoppingVoucher(shopId: string, voucherCode: string) {
    return axiosDashboard.post(`/api/dashboard/shops/${shopId}/shoppingVouchers`, { code: voucherCode });
}

export async function createMoneyTransfer(shopId: string, vouchersCodes: Array<string>) {
    return axiosDashboard.post(`/api/dashboard/shops/${shopId}/shoppingVouchers/moneyTransfers`, vouchersCodes);
}

export async function getMoneyTransfers(shopId: string, page = 1, pageSize: number = DEFAULT_PAGE_SIZE) {
    return axiosDashboard
        .get(`/api/dashboard/shops/${shopId}/shoppingVouchers/moneyTransfers`, {
            params: { pageSize, page },
        })
        .then((resp) => resp.data);
}

export async function getMoneyTransferPDF(shopId: string, moneyTransferId: string) {
    return axiosDashboard
        .get(`/api/dashboard/shops/${shopId}/shoppingVouchers/moneyTransfers/${moneyTransferId}/pdf`, {
            responseType: 'blob',
        })
        .then((response) => {
            /*console.log(response)
        const headers = response.headers
        const contentDisposition:string = headers['content-disposition'];

        if(headers['Content-Type']!== 'application/octet-stream' || contentDisposition===undefined)
            return Promise.reject("Invalid headers response")

        const matches = contentDisposition.match(CONTENT_DISPOSITION_REGEX);
        if(matches === null || matches.groups === undefined || matches.groups.charset || matches.groups.filename)
            return Promise.reject("Invalid charset or filename")*/
            const time = new Date().getTime();
            saveAs(response.data, `${time}_moneyTransfer.pdf`);
        });
}

export async function getCouponInfo(couponCode: string) {
    return axiosDashboard.get(`/api/dashboard/giftCards/${couponCode}`).then((response) => response.data.card);
}

export async function redeemCoupon(couponCode: string, shopId: string) {
    return axiosDashboard
        .post(`/api/dashboard/giftCards/${couponCode}`, { shopId })
        .then((response) => response.data.card);
}

export async function getCoupons(shopId: string) {
    return axiosDashboard.get(`/api/dashboard/shops/${shopId}/giftCards`).then((response) => response.data);
}

export async function uploadImage(shopId: string, image: File, onProgress?: (ProgressEvent: ProgressEvent) => void) {
    const form = new FormData();
    form.append('shopImage', image);

    return axiosDashboard
        .post(`/api/dashboard/shops/${shopId}/image`, form, {
            onUploadProgress: onProgress,
        })
        .then((response) => response.data);
}

export const getClusuCouponInfo = async (coupon_code: string) => {
    //trackCouponCheck({ coupon_code: coupon_code });
    console.log('base', process.env.REACT_APP_AZFUNC_BASE_URL);
    const url = urljoin(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        process.env.REACT_APP_AZFUNC_BASE_URL!,
        '/api/coupon/',
        coupon_code
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    console.log('base', process.env.REACT_APP_AZFUNC_BASE_URL!, url);

    return Axios.get(url).then((response) => {
        return response.data;
    });
};

export const redeemClusuCoupon = async (shop_code: string, clusuCouponCode: string, clusuSecretCode: string) => {
    return Axios.post(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        urljoin(process.env.REACT_APP_AZFUNC_BASE_URL!, '/api/coupon/redeem'),
        {
            shop_code: shop_code,
            coupon_code: clusuCouponCode,
            coupon_secret_code: clusuSecretCode,
        }
    ).then((response) => {
        return response.data;
    });
};

export const getShopCategories = async (): Promise<Array<Category>> => {
    return axiosDashboard.get('api/Categories').then((response) => response.data);
};
