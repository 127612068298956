//import { getShop } from "app/services/adminApi";
import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { fetchShop, fetchShopError, fetchShopSuccess } from './shopSlice';
import shoppingVouchers from './shoppingVouchers/shoppingVouchersSaga';
import { getShop } from 'app/services/adminApi';
import { Shop } from 'app/models';

function* fetchShopEvent() {
    yield takeLatest(fetchShop, function* (action: PayloadAction<string>) {
        try {
            const shop: Shop = yield call(getShop, action.payload);
            /*const [shop, categories,coupons] = yield all(
                [call(getShop,action.payload),
                call(getCategories),
                call(getCoupons,action.payload)])*/

            yield put(fetchShopSuccess({ shop }));
        } catch (e) {
            yield put(fetchShopError(e as Error));
        }
    });
}

export default function* shopSaga() {
    yield all([fetchShopEvent(), shoppingVouchers()]);
}
