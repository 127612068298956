import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PageInfo, ShoppingVoucher, ShoppingVoucherResponse } from 'app/models';
import { LoadableAPIState, ShopPagedAPIRequest } from 'app/models/APIModels';

type ShoppingVouchersState = LoadableAPIState &
    PageInfo & {
        vouchers: Array<ShoppingVoucher>;
        selectedVouchers: Array<string>;
    };

const initialState: ShoppingVouchersState = {
    vouchers: [],
    selectedVouchers: [],
    isLoading: false,
    currentPage: 1,
    totPages: 0,
    pageSize: 1,
};

const shoppingVouchersSlice = createSlice({
    name: 'backoffice/shop/shoppingvouchers',
    initialState: initialState,
    reducers: {
        loadVouchers: (state: ShoppingVouchersState, action: PayloadAction<ShopPagedAPIRequest | undefined>) => {
            state.isLoading = true;
            state.error = undefined;
            //clear the voucher list only if the page is not specificed (on load)
            if (action.payload && action.payload.page === undefined) {
                state.vouchers = [];
                state.selectedVouchers = [];
            }
        },
        loadVouchersSuccess: (state: ShoppingVouchersState, action: PayloadAction<ShoppingVoucherResponse>) => {
            return {
                ...state,
                ...action.payload.pageInfo,
                isLoading: false,
                vouchers: action.payload.entities,
            };
        },
        loadVouchersError: (state: ShoppingVouchersState, action: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        clearSelectedVouchers: (state: ShoppingVouchersState) => {
            state.selectedVouchers = [];
        },
        toggleSelectedVoucher: (state: ShoppingVouchersState, action: PayloadAction<string>) => {
            const selectedVouchers = state.selectedVouchers;
            const id = action.payload;
            const index = selectedVouchers.indexOf(id);
            if (index === -1) {
                state.selectedVouchers = [...selectedVouchers, id];
            } else {
                state.selectedVouchers = [
                    ...selectedVouchers.slice(0, index),
                    ...selectedVouchers.slice(index + 1, selectedVouchers.length),
                ];
            }
        },
    },
});

export const { loadVouchers, loadVouchersSuccess, loadVouchersError, toggleSelectedVoucher, clearSelectedVouchers } =
    shoppingVouchersSlice.actions;

export default shoppingVouchersSlice.reducer;
