import React, { Component, PropsWithChildren } from 'react';
import { reactPlugin } from 'app/services/AppInsight';

export class FatalError extends Component<
    PropsWithChildren<Record<string, unknown>>,
    { hasError: boolean; errorMessage?: string }
> {
    constructor(props: PropsWithChildren<Record<string, unknown>>) {
        super(props);
        this.state = {
            hasError: false,
            errorMessage: undefined,
        };
    }

    static getDerivedStateFromError(error: Error) {
        reactPlugin.trackException({
            exception: error,
        });
        return { hasError: true, errorMessage: error.message };
    }

    //componentDidCatch(error: Error, errorInfo: ErrorInfo) {}

    render() {
        if (this.state.hasError)
            return (
                <div className="splash-screen">
                    <div className="error-container-full-page">
                        <h1 className="error-code">Ops...</h1>
                        <p>Si è verificato un errore, riprova più tardi</p>
                        <p>
                            <small className="text-muted">{this.state.errorMessage}</small>
                        </p>
                    </div>
                </div>
            );
        else return <>{this.props.children}</>;
    }
}
