import React from 'react';

export default function PageNotFound() {
    return (
        <div className="splash-screen">
            <div className="error-container-full-page">
                <h1 className="error-code">404</h1>
                <p>La pagina che stai cercando non è stata trovata</p>
                <p>
                    <small className="text-muted">Verifica che l&apos;indirizzo sia corretto</small>
                </p>
            </div>
        </div>
    );
}
