import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createAction } from '@reduxjs/toolkit';
//import {persistStore, persistReducer} from 'redux-persist';
//import storage from 'redux-persist/lib/storage';

import authReducer from '../auth/authSlice';
import backofficeReducer from 'app/routes/backoffice/backofficeSlice';

import mainSaga from './appSaga';

//Action emitted during app init
export const appInitAction = createAction('APP_INIT');
export type AppInitAction = ReturnType<typeof appInitAction>;

//saga middleware
const sagaMiddleWare = createSagaMiddleware();

//reducers
const reducers = combineReducers({
    auth: authReducer,
    backoffice: backofficeReducer,
});

//redux-persist config
/*const persistConfig = {
  key:'root',
  storage,
  blacklist:['auth']
};*/

//const persistedReducer = persistReducer(persistConfig,reducers);

//create store
const store = configureStore({
    reducer: reducers,
    devTools: process.env.NODE_ENV === 'development' ? true : false,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            //disable thunk (use SAGA)
            thunk: false,
            //disable serializeable check to use redux-persist
            serializableCheck: false,
        }).concat(sagaMiddleWare);
    },
});

//run main saga event loop
sagaMiddleWare.run(mainSaga);

export type RootState = ReturnType<typeof store.getState>;
//export const persistor = persistStore(store);
export default store;
