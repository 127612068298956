import { login, signUp } from 'app/auth/authSlice';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function AuthRequired(): JSX.Element {
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <div className="authExternalContainer">
            <div className="card col-md-6">
                <div className="card-body">
                    <h5 className="card-title">Esegui l&apos;accesso</h5>
                    <h6 className="card-subtitle mb-2 text-muted">Stai accedeno ad un&apos;area riservata</h6>
                    <div className="card-text">
                        <div>
                            <p>
                                <small>Per accedere a quest&apos;area è necessario eseguire l&apos;accesso</small>
                            </p>
                            <p>
                                Se è la prima volta che accedi{' '}
                                <a className="" href="#" onClick={() => dispatch(signUp())}>
                                    Registrati
                                </a>
                                , altrimenti{' '}
                            </p>
                            <button className="btn btn-primary" onClick={() => dispatch(login())}>
                                Esegui l&apos;accesso
                            </button>
                            <button className="btn btn-link" onClick={() => history.goBack()}>
                                Indietro
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
