import * as msal from '@azure/msal-browser';
import * as Bowser from 'bowser';

//view https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md

export interface AuthConfiguration {
    clientId: string;
    B2CDomain: string;
    //redirectUri: string,
    //postRedirectUri: string,
    autorities: {
        login: string;
        [key: string]: string;
    };
    scopes: Array<string>;
}

class AuthNotInitError extends Error {
    constructor() {
        super(
            'AuthService: this service has not been initialized! Be sure to call init method before start using this service'
        );
    }
}

class AuthService {
    private STORAGE_KEY = 'Auth_Hint';
    private msalConfig?: msal.Configuration;
    protected client?: msal.PublicClientApplication;

    protected _account?: msal.AccountInfo;
    protected configuration?: AuthConfiguration;

    private browser = Bowser.getParser(window.navigator.userAgent);
    protected isIE = this.browser.satisfies({ ie: '<12' });
    //protected isIE = true

    constructor(configuration?: AuthConfiguration) {
        if (configuration) {
            this.init(configuration);
        }
    }

    public init(configuration: AuthConfiguration) {
        this.configuration = configuration;

        const B2CDomain = `${configuration.B2CDomain}.b2clogin.com`;
        const loginAuthority = `https://${this.configuration?.B2CDomain}.b2clogin.com/${this.configuration?.B2CDomain}.onmicrosoft.com/${this.configuration?.autorities.login}`;
        this.msalConfig = {
            auth: {
                clientId: configuration.clientId,
                authority: loginAuthority,
                knownAuthorities: [B2CDomain],
                //redirectUri: window.location.href,
                //postLogoutRedirectUri: window.location.href,
            },
            cache: {
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: true,
            },
        };

        this.client = new msal.PublicClientApplication(this.msalConfig);
    }

    /**
     * get registered redirect uri
     */
    private getRedirectURI() {
        return window.location.protocol + '//' + window.location.host + '/';
    }

    /**
     * attempt to perform a popup login
     */
    async login() {
        const loginAuthority = `https://${this.configuration?.B2CDomain}.b2clogin.com/${this.configuration?.B2CDomain}.onmicrosoft.com/${this.configuration?.autorities.login}`;
        const options = {
            authority: loginAuthority,

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            scopes: ['openid', 'offline_access', ...this.configuration!.scopes],

            //prompt: 'select_account',
            redirectUri: this.getRedirectURI(),
        };

        if (this.isIE) await this.loginRedirect(options);
        else await this.loginPopup(options);
    }

    /**
     * open popup or redirect if the user click on reset password
     */
    async resetPassword() {
        if (!this.client) throw new AuthNotInitError();
        const resetAutority = `https://${this.configuration?.B2CDomain}.b2clogin.com/${this.configuration?.B2CDomain}.onmicrosoft.com/${this.configuration?.autorities.passwordReset}`;
        const options = {
            authority: resetAutority,
            scopes: ['openid', 'offline_access', ...(this.configuration?.scopes ?? [])],
            //prompt: 'select_account',
            redirectUri: this.getRedirectURI(),
        };

        if (this.isIE) await this.loginRedirect(options);
        else await this.loginPopup(options);
    }

    /**
     * open popup or redirect if the user click on edit password
     */
    async editProfile() {
        if (!this.client) throw new AuthNotInitError();
        const editAutority = `https://${this.configuration?.B2CDomain}.b2clogin.com/${this.configuration?.B2CDomain}.onmicrosoft.com/${this.configuration?.autorities.editProfile}`;
        const options = {
            authority: editAutority,
            scopes: ['openid', 'offline_access', ...(this.configuration?.scopes ?? [])],
            //prompt: 'select_account',
            redirectUri: this.getRedirectURI(),
        };

        if (this.isIE) await this.loginRedirect(options);
        else await this.loginPopup(options);
    }

    /**
     * Open popup or redirect if the user wants to sign up (register)
     */
    async signUp() {
        if (!this.client) throw new AuthNotInitError();
        const editAutority = `https://${this.configuration?.B2CDomain}.b2clogin.com/${this.configuration?.B2CDomain}.onmicrosoft.com/${this.configuration?.autorities.signUp}`;
        const options = {
            authority: editAutority,
            scopes: ['openid', 'offline_access', ...(this.configuration?.scopes ?? [])],
            //prompt: 'select_account',
            redirectUri: this.getRedirectURI(),
        };

        if (this.isIE) await this.loginRedirect(options);
        else await this.loginPopup(options);
    }

    /**
     * attempt to login with popup method
     * @param options
     */
    private async loginPopup(options: msal.PopupRequest) {
        if (!this.client) throw new AuthNotInitError();
        const loginResponse: msal.AuthenticationResult = await this.client.loginPopup(options);
        this.handleResponse(loginResponse);
    }

    /**
     * attempt to lgoin with redirect
     * @param options
     */
    private async loginRedirect(options: msal.RedirectRequest) {
        if (!this.client) throw new AuthNotInitError();
        await this.client.loginRedirect(options);
    }

    /**
     * logout the user
     */
    async logout() {
        if (!this.client) throw new AuthNotInitError();
        this.clearAccountHint();
        const logoutRequest = {
            //account: this._account,
            postLogoutRedirectUri: this.getRedirectURI(),
        };
        await this.client.logout(logoutRequest);
    }

    /**
     * Initialize the module after a redirect or if a session already exist
     */
    public async loadModule() {
        if (!this.client) throw new AuthNotInitError();
        //check if coming from a redirect
        try {
            const response = await this.client.handleRedirectPromise();
            this.handleResponse(response);
            //if not, try to load an exisiting session
            if (!this._account) {
                await this.attemptSilent();
            }
        } catch (err) {
            if (
                err instanceof msal.AuthError &&
                err.errorCode === 'access_denied' &&
                err.errorMessage.includes('AADB2C90118')
            )
                await this.resetPassword();
        }
    }

    /**
     * create an account object
     * @param response
     */
    private handleResponse(response: msal.AuthenticationResult | null) {
        if (response != null) {
            //back from a redirect login
            this._account = response.account ?? undefined;
        } else {
            this._account = this.selectAccount();
        }
        this.saveAccountHint(this._account);
    }

    private saveAccountHint(account?: msal.AccountInfo) {
        if (account) localStorage.setItem(this.STORAGE_KEY, account.username);
    }

    private loadAccountHint(): string | undefined {
        const hint = localStorage.getItem(this.STORAGE_KEY);
        return hint ? hint : undefined;
    }

    private clearAccountHint() {
        localStorage.removeItem(this.STORAGE_KEY);
    }

    /**
     * Get the correct account to signin, if there are multiple accounts select the first one
     */
    private selectAccount(): msal.AccountInfo | undefined {
        if (!this.client) throw new AuthNotInitError();

        const accounts: msal.AccountInfo[] = this.client.getAllAccounts();
        if (accounts.length > 1) {
            console.info('Multiple Accounts found, selected first one');
            return accounts[0];
        } else if (accounts.length === 1) {
            return accounts[0];
        } else {
            console.info('No account found!');
            return undefined;
        }
    }

    /**
     * attempt to perform a silent login
     */
    public async attemptSilent(): Promise<msal.AccountInfo | undefined> {
        if (!this.client) throw new AuthNotInitError();
        try {
            const loginHint: string | undefined = this.loadAccountHint();
            if (loginHint === undefined) {
                console.info('No session found');
                return;
            }
            const loginResponse = await this.client.ssoSilent({
                scopes: ['openid', 'offline_access', ...(this.configuration?.scopes ?? [])],
                prompt: 'none',
                loginHint: this.loadAccountHint(),
                redirectUri: this.getRedirectURI(),
            });
            this.handleResponse(loginResponse);
            return this._account;
        } catch (err) {
            if (err instanceof msal.InteractionRequiredAuthError) {
                //active login is required
                console.info('Silent SSO fail, fallback to interactive');
                //this.login()
            } else {
                console.error(err);
                console.info('Unable to complete Silent SSO');
                //this.clearAccountHint();
            }
        }
    }

    async acquireToken(options: msal.SilentRequest): Promise<msal.AuthenticationResult | undefined> {
        if (!this.client) throw new AuthNotInitError();
        options = { ...options, redirectUri: this.getRedirectURI() };
        try {
            const response = await this.client.acquireTokenSilent(options);
            if (response == null) return await this.acquireTokenInteractive(options);
            return response;
        } catch (err) {
            if (err instanceof msal.InteractionRequiredAuthError) {
                return await this.acquireTokenInteractive(options);
            } else {
                throw err;
            }
        }
    }

    private async acquireTokenInteractive(options: msal.SilentRequest): Promise<msal.AuthenticationResult | undefined> {
        if (this.isIE) await this.acquireTokenRedirect(options);
        else return await this.acquireTokenPopup(options);
    }

    private async acquireTokenPopup(options: msal.SilentRequest): Promise<msal.AuthenticationResult> {
        if (!this.client) throw new AuthNotInitError();
        return await this.client.acquireTokenPopup(options);
    }

    private async acquireTokenRedirect(options: msal.SilentRequest): Promise<void> {
        if (!this.client) throw new AuthNotInitError();
        await this.client.acquireTokenRedirect(options);
    }

    public async getIdToken() {
        if (!this.client) throw new AuthNotInitError();
        const options: msal.SilentRequest = {
            scopes: ['openid'],
            account: this._account,
        };
        const token = await this.acquireToken(options);
        if (token)
            return {
                idToken: token.idToken,
                idTokenClaims: token.idTokenClaims,
            };
        else throw new Error('Unable to retrive token from B2C');
    }

    public getAccount(): msal.AccountInfo | undefined {
        return this._account;
    }

    public isAuthenticated(): boolean {
        return this._account ? true : false;
    }
}

class ClusuAuthService extends AuthService {
    public async getApiToken(): Promise<string> {
        if (!this.client) throw new AuthNotInitError();
        const options: msal.SilentRequest = {
            scopes: ['openid', 'offline_access', ...(this.configuration?.scopes ?? [])],
            account: this._account,
        };
        const token = await this.acquireToken(options);
        if (token) return token.accessToken;
        else throw new Error('Unable to retrive token from B2C');
    }
}

//create the service wihtout initialization
const env = process.env;
const authService = new ClusuAuthService({
    clientId: env.REACT_APP_MSAL_CLIENT_ID ?? '',
    B2CDomain: env.REACT_APP_MSAL_B2CDOMAIN ?? '',
    autorities: {
        login: env.REACT_APP_MSAL_AUTH_LOGIN ?? '',
        passwordReset: env.REACT_APP_MSAL_AUTH_PASS_RES ?? '',
        editProfile: env.REACT_APP_MSAL_EDIT_PROFILE ?? '',
        signUp: env.REACT_APP_MSAL_SIGN_UP ?? '',
    },
    scopes: [env.REACT_APP_MSAL_SCOPE ?? ''],
});

export default authService;
