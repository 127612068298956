import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Shop } from 'app/models';
import ShoppingVouchersReducer from './shoppingVouchers/shoppingVouchersSlice';
import MoneyTransfersReducer from './shoppingVouchers/moneyTransferSlice';

interface ShopInfoState {
    isLoading: boolean;
    shop?: Shop;
    error?: Error;
}

const initialState: ShopInfoState = {
    isLoading: true,
};

const ShopInfoSlice = createSlice({
    name: 'admin',
    initialState: initialState,
    reducers: {
        //the oayload of this action is the shopID to fetch
        fetchShop: (state, _action: PayloadAction<string>) => {
            state.isLoading = true;
            state.shop = undefined;
            state.error = undefined;
        },
        fetchShopSuccess: (state, action: PayloadAction<{ shop: Shop }>) => {
            state.isLoading = false;
            state.shop = action.payload.shop;
            state.error = undefined;
        },
        fetchShopError: (state, action: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});

const reducer = combineReducers({
    shopInfo: ShopInfoSlice.reducer,
    shoppingVouchers: ShoppingVouchersReducer,
    moneyTransfers: MoneyTransfersReducer,
});

export const { fetchShop, fetchShopSuccess, fetchShopError } = ShopInfoSlice.actions;

export default reducer;
