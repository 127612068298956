import { put, takeLatest, call } from 'redux-saga/effects';
import {
    login as loginAction,
    loginError,
    loginStart,
    loginSuccess,
    logout as logoutAction,
    noLogin,
    editProfile as editProfileAction,
    signUp as signUpAction,
} from './authSlice';
import authService from './authService';
import { AccountInfo, BrowserAuthError, AuthError } from '@azure/msal-browser';
import { appInitAction } from '../store';

/*
 * Handle login event from UI
 */
function* login() {
    //start login prosess event
    yield put(loginStart());
    try {
        try {
            //start login on auth service
            yield call([authService, authService.login]);
        } catch (err) {
            if (
                err instanceof AuthError &&
                err.errorCode === 'access_denied' &&
                err.errorMessage.includes('AADB2C90118')
            )
                yield call([authService, authService.resetPassword]);
        }
        //save account on redux
        yield setAccount();
    } catch (error) {
        if (error instanceof BrowserAuthError && error.errorCode === 'user_cancelled') {
            console.log("Login interrotto dall'utente");
            yield put(noLogin());
        } else {
            console.error(error);
            yield put(loginError(error as Error));
        }
    }
}

/**
 * APP INIT
 */
function* appInit() {
    //start login prosess event
    yield put(loginStart());
    try {
        //check if coming from a redirect or a session exist
        yield call([authService, authService.loadModule]);

        //save account on redux
        yield setAccount();
    } catch (error) {
        console.error(error);
        yield put(loginError(error as Error));
    }
}

/**
 * Logout
 */
function* logout() {
    yield call([authService, authService.logout]);
}

function* editProfile() {
    try {
        yield call([authService, authService.editProfile]);
        //save account on redux
        yield setAccount();
    } catch (error) {
        if (error instanceof BrowserAuthError && error.errorCode === 'user_cancelled') {
            console.info("Edit annullato dall'utente");
        } else {
            console.error(error);
        }
    }
}

function* signUp() {
    try {
        yield call([authService, authService.signUp]);
        yield setAccount();
    } catch (error) {
        if (error instanceof BrowserAuthError && error.errorCode === 'user_cancelled') {
            console.info("signUp annullato dall'utente");
        } else {
            console.error(error);
        }
    }
}

function* setAccount() {
    //get account
    const account: AccountInfo | undefined = yield call([authService, authService.getAccount]);
    //sucess logged in
    if (account) {
        const idToken: {
            idTokenClaims: {
                given_name: string;
                family_name: string;
                emails: Array<string>;
            };
        } = yield call([authService, authService.getIdToken]);
        const first_name = idToken.idTokenClaims.given_name;
        const last_name = idToken.idTokenClaims.family_name;
        yield put(
            loginSuccess({
                account: account,
                user: {
                    email: idToken.idTokenClaims.emails[0],
                    first_name: first_name,
                    last_name: last_name,
                    display_name: first_name + ' ' + last_name,
                },
            })
        );
    }
    //no login
    else yield put(noLogin());
}

/**
 * SAGA event processor
 */
export default function* mainEventProcessor() {
    yield takeLatest(loginAction, login);
    yield takeLatest(appInitAction, appInit);
    yield takeLatest(logoutAction, logout);
    yield takeLatest(editProfileAction, editProfile);
    yield takeLatest(signUpAction, signUp);
}
