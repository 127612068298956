import { Shop } from 'app/models';
import { getShops } from 'app/services/adminApi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchActivities, fetchActivitiesError, fetchActivitiesSuccess } from './dashboardSlice';

function* fetchData() {
    try {
        const shops: Shop[] = yield call(getShops);
        //const categories = yield call(getCategories)
        yield put(fetchActivitiesSuccess({ shops: shops, categories: [] }));
    } catch (e) {
        yield put(fetchActivitiesError(e as Error));
    }
}

export default function* dashboardSaga() {
    yield takeLatest(fetchActivities, fetchData);
}
