import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory();

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_INSIGHT_INSTRUMENTATION_KEY,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
    },
});

appInsights.loadAppInsights();

export const appInsightConnect = (component: React.ComponentType, componentName?: string, className?: string) => {
    return withAITracking(reactPlugin, component, componentName, className);
};

export const trackFatalError = (exception: Error, values: Record<string, unknown> = {}) => {
    appInsights.trackException({
        exception: exception,
        properties: values,
    });
    appInsights.trackEvent({
        name: 'FATAL_ERROR',
        properties: values,
    });
};

export const trackCheckoutEvent = (values: Record<string, unknown> = {}) => {
    appInsights.trackEvent({
        name: 'CHECKOUT',
        properties: values,
    });
};

export const trackStartCreateOrder = (values = {}) => {
    appInsights.trackEvent({
        name: 'ORDER_START',
        properties: values,
    });
};

export const trackCreatedOrder = (values = {}) => {
    appInsights.trackEvent({
        name: 'ORDER_CREATED',
        properties: values,
    });
};

export const trackOrderApproved = (values = {}) => {
    appInsights.trackEvent({
        name: 'ORDER_APPROVED',
        properties: values,
    });
};

export const trackOrderError = (values = {}) => {
    appInsights.trackEvent({
        name: 'ORDER_ERROR',
        properties: values,
    });
};

export const trackPayPalButtonError = (values = {}) => {
    appInsights.trackEvent({
        name: 'PAYPAL_BUTTON_ERROR',
        properties: values,
    });
};

export const trackOrderComplete = (values = {}) => {
    appInsights.trackEvent({
        name: 'ORDER_COMPLETE',
        properties: values,
    });
};

/*export const trackSocialClick = (person,social)=>{
    appInsights.trackEvent({
        name: 'PERSON_SOCIAL_CLICK',
        properties : {
            person: person,
            social:social
        }
    })
}*/

export const trackCouponCheck = (values = {}) => {
    appInsights.trackEvent({
        name: 'COUPON_CHECK',
        properties: values,
    });
};

export const trackCouponRedeem = (values = {}) => {
    appInsights.trackEvent({
        name: 'COUPON_REDEEM',
        properties: values,
    });
};

export const trackCouponRedeemError = (values = {}) => {
    appInsights.trackEvent({
        name: 'COUPON_REDEEM_ERROR',
        properties: values,
    });
};

export { reactPlugin, appInsights, browserHistory };
export default appInsightConnect;
