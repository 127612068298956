import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { lazy } from '@loadable/component';
import AuthRequiredPage from 'app/auth/components/AuthRequiredPage';
import PageNotFound from 'app/commons/components/PageNotFound';

const Dashboard = lazy(() => import('./backoffice/dashboard'));
const Shop = lazy(() => import('./backoffice/shop'));
//const ShopEdit = lazy(() => import('./backoffice/shop/edit'));
//const ShopNew = lazy(() => import("./dashboard/shop/New"));

const Routes = () => {
    return (
        <Switch>
            <Route path="/" exact>
                <AuthRequiredPage>
                    <Dashboard />
                </AuthRequiredPage>
            </Route>

            <Route path="/shop/:id">
                <AuthRequiredPage>
                    <Shop />
                </AuthRequiredPage>
            </Route>

            {/*<Route path="/dashboard/shop/new">
				<AuthRequiredPage>
					<ShopNew />
				</AuthRequiredPage>
			</Route>*/}

            <Route component={PageNotFound} />
        </Switch>
    );
};

export default Routes;
