import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import 'bootstrap';
import Bowser from 'bowser';

//import makeServer from './mock/server'

/*if(process.env.NODE_ENV === 'development'){
    makeServer();
}*/

const browser = Bowser.getParser(window.navigator.userAgent);
const isOldBrowser = browser.satisfies({
    ie: '<=11',
    firefox: '<=27',
    opera: '<=12',
    safari: '<=6',
    chrome: '<=20',
});

if (isOldBrowser) {
    document.getElementById('old-browser-alert')?.classList.remove('hidden');
}

const appStartSplashScreen = document.getElementById('app-start-splash-screen');

ReactDOM.render(
    <React.StrictMode>
        <App appStartLoader={appStartSplashScreen} />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
