import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MoneyTransfer, PageInfo } from 'app/models';
import { LoadableAPIState, ShopPagedAPIRequest } from 'app/models/APIModels';

type MoneyTransferState = LoadableAPIState &
    PageInfo & {
        moneyTransfers: Array<MoneyTransfer>;
    };

const initialState: MoneyTransferState = {
    isLoading: false,
    currentPage: 1,
    pageSize: 1,
    totPages: 0,
    moneyTransfers: [],
};

const moneyTransferSlice = createSlice({
    name: 'moneyTransfer',
    initialState: initialState,
    reducers: {
        loadMoneyTransfers: (state: MoneyTransferState, _action: PayloadAction<ShopPagedAPIRequest | undefined>) => {
            state.isLoading = true;
            state.error = undefined;
            state.moneyTransfers = [];
        },
        loadMoneyTransfersSuccess: (
            state: MoneyTransferState,
            action: PayloadAction<{
                pageInfo: PageInfo;
                entities: Array<MoneyTransfer>;
            }>
        ) => {
            return {
                ...state,
                ...action.payload.pageInfo,
                isLoading: false,
                moneyTransfers: action.payload.entities,
            };
        },
        loadMoneyTransfersError: (state: MoneyTransferState, action: PayloadAction<Error>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
    },
});

export const { loadMoneyTransfers, loadMoneyTransfersSuccess, loadMoneyTransfersError } = moneyTransferSlice.actions;

export default moneyTransferSlice.reducer;
