import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Category, Shop } from 'app/models';

interface DashbordState {
    isLoading: boolean;
    error?: Error;
    shops: Array<Shop>;
    categories: Array<Category>;
}

const initState: DashbordState = {
    isLoading: true,
    shops: [],
    categories: [],
};

const dashboardSlice = createSlice({
    name: 'admin/dashboard',
    initialState: initState,
    reducers: {
        fetchActivities: (state) => {
            state.isLoading = true;
            state.error = undefined;
        },
        fetchActivitiesSuccess: (
            state,
            action: PayloadAction<{
                shops: Array<Shop>;
                categories: Array<Category>;
            }>
        ) => {
            state.isLoading = false;
            state.shops = action.payload.shops;
            state.categories = action.payload.categories;
        },
        fetchActivitiesError: (state, action: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});

export const { fetchActivities, fetchActivitiesSuccess, fetchActivitiesError } = dashboardSlice.actions;

export default dashboardSlice.reducer;
