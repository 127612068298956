import React, { useEffect, useState } from 'react';

export default function AuthInProgress() {
    const [longLoading, setlongLoading] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setlongLoading(true);
        }, 5000);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <div className="splash-screen">
            <div className="splash-screen-container">
                <div className="splash-screen-circle-tick"></div>
                <div className="splash-screen-content">
                    <h3>Accesso in corso</h3>
                    <p className="text-muted">
                        {!longLoading && <small>Attendere prego</small>}
                        {longLoading && (
                            <small>
                                Sembra che l&apos;accesso richieda più tempo del previsto
                                <br />
                                Se presente, effettua l&apos;accesso nel popup.
                            </small>
                        )}
                    </p>
                </div>
            </div>
        </div>
        /*
        <div className="authExternalContainer">
            <div className="card col-md-6">
                <div className="card-body">
                    <h5 className="card-title">
                        Accesso in corso
                    </h5>
                    <h6 className="card-subtitle mb-2 text-muted">
                        Attendere prego
                    </h6>
                    <div className="card-text mt-4">
                        <ContinuousProgress />
                    </div>
                </div>
            </div>
        </div>*/
    );
}
