import React, { Suspense, useEffect } from 'react';
import './App.scss';

import { Provider } from 'react-redux';
//import { PersistGate } from "redux-persist/integration/react";
import { Router } from 'react-router-dom';
import store, { appInitAction } from './store';

import Routes from './routes/Routes';
import AppLoading from './AppLoading';
import { NotificationsProvider } from 'app/commons/notifications';
import { FatalError } from './routes/Errors';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, browserHistory } from 'app/services/AppInsight';

function App({ appStartLoader }: { appStartLoader: HTMLElement | null }) {
    useEffect(() => {
        appStartLoader?.remove();
    }, [appStartLoader]);

    return (
        <FatalError>
            <NotificationsProvider>
                <Provider store={store}>
                    <AppInsightsContext.Provider value={reactPlugin}>
                        {/*<PersistGate loading={<AppLoading />} persistor={persistor}>*/}
                        <Suspense fallback={<AppLoading />}>
                            <Router history={browserHistory}>
                                <Routes />
                            </Router>
                        </Suspense>

                        {/*</PersistGate>*/}
                    </AppInsightsContext.Provider>
                </Provider>
            </NotificationsProvider>
        </FatalError>
    );
}

store.dispatch(appInitAction());

export default App;
