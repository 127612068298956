import { call, put, select, takeLatest } from 'redux-saga/effects';
import { loadVouchers, loadVouchersError, loadVouchersSuccess } from './shoppingVouchersSlice';
import { loadMoneyTransfers, loadMoneyTransfersError, loadMoneyTransfersSuccess } from './moneyTransferSlice';
import { getMoneyTransfers, getShoppingVouchers } from 'app/services/adminApi';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ShopPagedAPIRequest } from 'app/models/APIModels';
import { MoneyTransfer, PageInfo, ShoppingVoucherResponse } from 'app/models';

function* getPageInfo(shopInfo?: ShopPagedAPIRequest) {
    let { shopId } = {
        ...{ shopId: undefined },
        ...shopInfo,
    };

    const { page, pageSize } = {
        ...{ page: undefined, pageSize: undefined },
        ...shopInfo,
    };

    //if the shopping id is undefined (use the shop in redux)
    if (shopId === undefined) shopId = yield select((state: RootState) => state.backoffice.shop.shopInfo.shop?.id);

    return { shopId, page, pageSize };
}

function* fetchVouchers(action: PayloadAction<ShopPagedAPIRequest | undefined>) {
    try {
        const { shopId, page, pageSize } = yield call(getPageInfo, action.payload);

        const r: ShoppingVoucherResponse = yield call(getShoppingVouchers, shopId, page, pageSize);
        yield put(loadVouchersSuccess(r));
    } catch (e) {
        yield put(loadVouchersError(e as Error));
    }
}

function* fetchMoneyTransfers(action: PayloadAction<ShopPagedAPIRequest | undefined>) {
    try {
        const { shopId, page, pageSize } = yield call(getPageInfo, action.payload);
        const response: {
            pageInfo: PageInfo;
            entities: Array<MoneyTransfer>;
        } = yield call(getMoneyTransfers, shopId, page, pageSize);
        yield put(loadMoneyTransfersSuccess(response));
    } catch (e) {
        yield put(loadMoneyTransfersError(e as Error));
    }
}

export default function* shoppingVouchersSaga() {
    yield takeLatest(loadVouchers, fetchVouchers);
    yield takeLatest(loadMoneyTransfers, fetchMoneyTransfers);
}
