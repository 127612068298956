import { login } from 'app/auth/authSlice';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function AuthFailed(props: { error: string }) {
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <div className="authExternalContainer">
            <div className="card col-md-6">
                <div className="card-body">
                    <h5 className="card-title">Accesso fallito</h5>
                    <h6 className="card-subtitle mb-2 text-muted">Si è verificato un errore durnate l&apos;accesso</h6>
                    <div className="card-text">
                        <p>
                            <small>{props.error}</small>
                        </p>
                        <button className="btn btn-primary" onClick={() => dispatch(login())}>
                            Esegui nuovamente l&apos;accesso
                        </button>
                        <button className="btn btn-link" onClick={() => history.goBack()}>
                            Indietro
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
