import React, { PropsWithChildren, useMemo, createContext, ReactNode, useContext, useState, useCallback } from 'react';
import cn from 'classnames';
import './notifications.scss';

const notificationsContext = createContext<ReturnType<typeof NotificationManager> | undefined>(undefined);
const { Provider } = notificationsContext;

export const NotificationsProvider = (props: PropsWithChildren<Record<string, unknown>>) => {
    const manager = NotificationManager();

    return (
        <Provider value={manager}>
            {props.children}

            <NotificationContainer />
        </Provider>
    );
};

export const useNotifications = () => {
    const ctx = useContext(notificationsContext);

    if (!ctx)
        throw Error('The `useNotifications` hook must be called from a descendent of the `NotificationsProvider`.');

    return ctx;
};

export type ERROR_TYPE = 'error';
export type INFO_TYPE = 'info';
export type WARN_TYPE = 'warn';
export type SUCCESS_TYPE = 'success';

export type NotificationType = SUCCESS_TYPE | INFO_TYPE | WARN_TYPE | ERROR_TYPE;
interface Notification {
    content: ReactNode;
    id: string;
    type: NotificationType;
    autoDismiss: boolean;
}

let notificationsCounter = 0;
const createNotification = (content: ReactNode, type: NotificationType, autoDismiss: boolean): Notification => {
    const notification: Notification = {
        content,
        id: notificationsCounter.toString(),
        type,
        autoDismiss,
    };
    notificationsCounter++;
    return notification;
};

const NotificationManager = () => {
    //list of current active notifications
    const [notifications, setNotifications] = useState<Array<Notification>>([]);

    /**
     * Add a new notification
     */
    const addNotification = useCallback(
        (content: ReactNode, type: NotificationType, autoDismiss = true) => {
            const newNotif = createNotification(content, type, autoDismiss);
            setNotifications((oldState) => [...oldState, newNotif]);
            return newNotif.id;
        },
        [setNotifications]
    );

    /**
     * Remove an existing notification
     */
    const removeNotification = useCallback(
        (id: string) => {
            setNotifications((oldState) => {
                const index = oldState.findIndex((notif) => notif.id === id);
                if (index < 0) {
                    //not found
                    return oldState;
                }
                return [...oldState.slice(0, index), ...oldState.slice(index + 1, oldState.length)];
            });
        },
        [setNotifications]
    );

    //set notification list to read only before export
    const elements = useMemo(() => Object.freeze(notifications), [notifications]);

    return { notifications: elements, addNotification, removeNotification };
};

const NotificationContainer = () => {
    const { notifications, removeNotification } = useNotifications();

    const close = useCallback(
        (id: string) => {
            removeNotification(id);
        },
        [removeNotification]
    );

    const elements = useMemo(() => {
        return notifications.map((notification, index) => {
            if (notification.autoDismiss) {
                setTimeout(() => {
                    close(notification.id);
                }, 4000);
            }

            return (
                <div
                    key={index}
                    className={cn('notification-box', {
                        [notification.type]: true,
                    })}
                >
                    <div className="d-flex justify-content-between notification-head">
                        {notification.autoDismiss && <div className="progress" />}

                        <i className="icon"></i>
                        <i className="icofont-close" onClick={() => close(notification.id)}></i>
                    </div>
                    <div className="notification-body">{notification.content}</div>
                </div>
            );
        });
    }, [notifications, close]);

    return <div id="notificationsContainer">{elements}</div>;
};
